<template>
  <CenterView fullScreen>
    <Card
      fullScreenIfMobile
      useStyle="max-width: 80vw; width: 80vw; max-height: 80vh; margin-top: 5vh"
    >
      <CenterView v-if="!buying && !activatingLicense">
        <!-- list all used licenses -->
        <!-- header -->

        <div
          class="header white--text text-left"
          style="align-self: flex-start; margin-left: 10px; margin-top: 20px"
        >
          <h3>{{ $t("licenses.purchase") }}</h3>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            align-self: flex-start;
            margin-left: 4px;
            margin-right: 4px;
            margin-top: 10px;
            flex-wrap: wrap;
            /* min-width: 370px; */
          "
        >
          <v-text-field
            v-model="licenses"
            style="flex: 2; min-width: 250px"
            :label="$t('licenses.count')"
            :placeholder="$t('licenses.countPlaceholder')"
            dark
            filled
            type="number"
            outlined
            dense
            :height="40"
            :rules="[rules.required, rules.min, rules.max, rules.number, rules.integer]"
          ></v-text-field>
          <v-btn height="40" style="flex: 1; min-width: 150px" color="success" dark @click="buy">
            <v-icon> mdi-cart </v-icon> {{ $t("licenses.checkout") }}
          </v-btn>
          <v-alert
            v-if="nbLicensesIsValid()"
            height="40"
            color="transparent"
            style="
              color: white;
              margin: 0;
              margin-left: 10px;
              padding: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
            "
          >
            {{ $t("licenses.total") }}: {{ calculateTotal() }}€
            <span v-if="hasMoreThanFiveLicenses()" style="color: lightgray"> (-10%) </span>
          </v-alert>
        </div>

        <div
          class="header white--text text-left"
          style="align-self: flex-start; margin-left: 10px; margin-top: 5px"
        >
          <h3>{{ $t("licenses.activeLicenses") }}</h3>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            align-self: flex-start;
            margin-left: 4px;
            margin-right: 4px;
            margin-top: 10px;
            flex-wrap: wrap;
            /* min-width: 370px; */
          "
          v-if="canActivateLicense && isUserLoaded"
        >
          <v-text-field
            v-model="licenseId"
            style="flex: 2; min-width: 250px"
            :label="$t('licenses.licenseId')"
            :placeholder="$t('licenses.enterLicenseId')"
            dark
            filled
            hide-details
            outlined
            dense
            :height="40"
          ></v-text-field>
          <v-btn
            height="40"
            style="flex: 1; min-width: 150px"
            color="primary"
            dark
            @click="useLicenseId"
          >
            {{ $t("licenses.useLicense") }}
          </v-btn>
        </div>
        <v-list
          dark
          style="
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 10px;
            width: 100%;
            margin-top: 15px;
            margin-bottom: 15px;
          "
        >
          <v-simple-table fixed-header v-if="hasAnyLicenses">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">{{ $t("licenses.activationDate") }}</th>
                  <th class="text-center">{{ $t("licenses.status") }}</th>
                  <th class="text-center">{{ $t("licenses.buyer") }}</th>
                  <th class="text-center">{{ $t("licenses.licenseId") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(license, index) in licensesData" :key="index">
                  <td>
                    {{
                      new Date(
                        (license.activationDate.seconds || license.activationDate._seconds) * 1000
                      ).toDateString()
                    }}
                  </td>
                  <td>{{ $t(`licenses.${license.active ? "active" : "expired"}`) }}</td>
                  <td>
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <v-avatar size="30" color="#fff" class="mr-2">
                        <img :src="license.buyerPhotoUrl" alt="profile picture" />
                      </v-avatar>
                      {{ license.buyerName }}
                    </div>
                  </td>
                  <td style="width: 20vw">
                    <Spoiler>{{ license.id }}</Spoiler>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-else dark style="margin: 10px">
            <v-progress-circular
              v-if="!isUserLoaded"
              indeterminate
              size="40"
              style="margin: 0 auto"
            />
            <span v-else> {{ $t("licenses.noLicenses") }} </span>
          </div>
        </v-list>
        <!-- list all bought licenses -->
        <div
          class="header white--text text-left"
          style="align-self: flex-start; margin-left: 10px; margin-top: 20px"
        >
          <h3>{{ $t("licenses.boughtLicenses") }}</h3>
        </div>
        <!-- checkbox for showing used licenses -->
        <v-checkbox
          v-model="showUsedLicenses"
          :label="$t('licenses.showUsedLicenses')"
          color="primary"
          dark
          style="margin-left: 10px; margin-top: 0px; align-self: flex-start; margin-bottom: -5px"
        ></v-checkbox>
        <v-list
          dark
          style="
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 10px;
            width: 100%;
            margin-bottom: 15px;
          "
        >
          <v-simple-table v-if="hasAnyBoughtLicenses">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">{{ $t("licenses.status") }}</th>
                  <th class="text-center">{{ $t("licenses.creationDate") }}</th>
                  <th class="text-center">{{ $t("licenses.user") }}</th>
                  <th class="text-center">{{ $t("licenses.licenseId") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(license, index) in filteredBoughtLicenses" :key="index">
                  <td>
                    <div v-if="license.used">
                      <div>
                        {{ $t("licenses.activatedOn") }}
                        {{ new Date(license.activationDate.seconds * 1000).toDateString() }}
                      </div>
                    </div>
                    <div
                      v-else
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <v-btn
                        style="margin-right: 10px"
                        color="primary"
                        dark
                        v-if="canUseBoughtLicense"
                        @click="activate(license)"
                      >
                        {{ $t("licenses.activate") }}
                      </v-btn>
                      <v-tooltip top color="error" v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-btn style="margin-right: 10px" color="primary" dark disabled>
                              {{ $t("licenses.activate") }}
                            </v-btn>
                          </div>
                        </template>
                        <span>{{ $t("licenses.cannotActivateMessage") }}</span>
                      </v-tooltip>
                      <v-btn color="primary" dark @click="copyLicenseId(license)">
                        {{ $t("licenses.copyId") }}
                      </v-btn>
                    </div>
                  </td>
                  <td>{{ new Date(license.creationDate.seconds * 1000).toDateString() }}</td>
                  <td>
                    <div v-if="!license.used">{{ $t("licenses.unused") }}</div>
                    <div
                      v-else
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <v-avatar size="30" color="#fff" class="mr-2">
                          <img :src="license.userPhotoUrl" alt="profile picture" />
                        </v-avatar>
                        {{ license.userName }}
                      </div>
                    </div>
                  </td>
                  <td style="width: 20vw">
                    <Spoiler>{{ license.id }}</Spoiler>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-else dark style="margin: 10px">
            <v-progress-circular
              v-if="!isUserLoaded"
              indeterminate
              size="40"
              style="margin: 0 auto"
            />
            <span v-else> {{ $t("licenses.noLicenses") }} </span>
          </div>
        </v-list>
      </CenterView>
      <CenterView v-if="buying">
        <v-progress-circular indeterminate size="40" color="white" style="margin: 0 auto" />
        <span style="color: white; margin-top: 20px">{{
          $t("licenses.buyingText", { nb: licenses })
        }}</span>
      </CenterView>
      <CenterView v-if="activatingLicense">
        <v-progress-circular indeterminate size="40" color="white" style="margin: 0 auto" />
        <span style="color: white; margin-top: 20px"> {{ $t("licenses.activatingText") }} </span>
      </CenterView>
    </Card>
  </CenterView>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CenterView from "../components/CenterView.vue";

let $t = () => "";

export default {
  name: "Buy",
  components: { CenterView },
  mounted() {
    $t = this.$t.bind(this);
  },
  data: () => ({
    licenses: 1,
    buying: false,
    activatingLicense: false,
    licenseId: "",
    showUsedLicenses: false,
    filteredBoughtLicenses: [],
    rules: {
      required: (value) => !!value || $t("rules.required"),
      min: (value) => parseInt(value, 10) > 0 || $t("rules.min"),
      max: (value) => parseInt(value, 10) <= 999 || $t("rules.max"),
      number: (value) => !Number.isNaN(parseInt(value, 10)) || $t("rules.number"),
      integer: (value) => parseInt(value, 10).toString() === value || $t("rules.integer"),
    },
  }),
  watch: {
    showUsedLicenses(newValue) {
      this.updateFilter();
    },
    licenses(newValue, oldValue) {
      if (this.rules.integer(newValue) !== true) {
        return;
      }
      const nb = parseInt(newValue, 10);
      const oldNb = parseInt(oldValue, 10);
      if (nb < 1) {
        this.licenses = "1";
      } else if (nb > 999) {
        this.licenses = "999";
      }
      // if (nb > 5) {
      //   if (nb > oldNb) {
      //     // ceil to the nearest 5
      //     this.licenses = (Math.ceil(nb / 5) * 5).toString();
      //   } else {
      //     // floor to the nearest 5
      //     this.licenses = (Math.floor(nb / 5) * 5).toString();
      //   }
      // }
    },
    boughtlicensesData: {
      handler() {
        this.updateFilter();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      user: "user",
      userData: "userData",
      licensesData: "licensesData",
      boughtlicensesData: "boughtlicensesData",
      isUserLoaded: "isUserLoaded",
    }),
    canUseBoughtLicense() {
      return (
        this.boughtlicensesData &&
        Object.values(this.boughtlicensesData).length > 0 &&
        this.canActivateLicense
      );
    },
    canActivateLicense() {
      return (
        !this.licensesData || !Object.values(this.licensesData).some((license) => license.active)
      );
    },
    hasAnyLicenses() {
      return this.licensesData && Object.values(this.licensesData).length > 0;
    },
    hasAnyBoughtLicenses() {
      return this.filteredBoughtLicenses && Object.values(this.filteredBoughtLicenses).length > 0;
    },
  },
  methods: {
    hasMoreThanFiveLicenses() {
      return false;
      // return parseInt(this.licenses, 10) >= 5;
    },
    back() {
      this.$router.push("/home");
    },
    async buy() {
      this.buying = true;
      this.buyLicense({
        quantity: parseInt(this.licenses, 10),
      });
      // this.subscribe({ price: "price_1KuJWyDGUgtZ68AHosKd7Zug", quantity: 1 });
    },
    ...mapActions(["subscribe", "buyLicense", "useLicense", "createSnackbar"]),
    activate(license) {
      this.activatingLicense = true;
      this.useLicense({
        licenseId: license.id,
      }).then(() => {
        this.activatingLicense = false;
        this.licenseId = "";
      });
    },
    copyLicenseId(license) {
      navigator.clipboard.writeText(license.id);
      this.createSnackbar({
        text: this.$t("snackbars.licenses.copied"),
        type: "info",
      });
    },
    updateFilter() {
      if (!this.boughtlicensesData) return;
      if (!this.showUsedLicenses) {
        this.filteredBoughtLicenses = {};
        Object.keys(this.boughtlicensesData).forEach((key) => {
          if (!this.boughtlicensesData[key].used) {
            this.filteredBoughtLicenses[key] = this.boughtlicensesData[key];
          }
        });
      } else {
        this.filteredBoughtLicenses = this.boughtlicensesData;
      }
    },
    nbLicensesIsValid() {
      return (
        this.rules.integer(this.licenses) &&
        this.rules.min(this.licenses) &&
        this.rules.max(this.licenses)
      );
    },
    calculateTotal() {
      const nbLicenses = parseInt(this.licenses, 10);
      const total = nbLicenses * 14.99;
      return total.toFixed(2);
      // if (nbLicenses < 5) return nbLicenses * 100;
      // return nbLicenses * 90;
    },
    useLicenseId() {
      this.activate({ id: this.licenseId });
    },
  },
};
</script>
