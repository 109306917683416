<template>
  <v-app id="app" data-app>
    <!-- send background props based on current route -->
    <Background v-bind="backgroundRouteData[routePath]" />
    <v-toolbar dark class="toolbar" v-if="!!user">
      <!-- <v-toolbar-title>Clumo</v-toolbar-title> -->
      <!-- back button  with an arrow-->
      <v-btn icon dark to="/home">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-layout row nowrap fill-heightjustify-center>
        <v-spacer></v-spacer>

        <!-- language selection menu -->
        <v-menu offset-y v-if="userData">
          <template v-slot:activator="{ on }">
            <v-btn depressed rounded text dark v-on="on" style="height: 55px">
              <v-icon medium style="padding-right: 5px">mdi-translate</v-icon>
              <flag :iso="userData.language ? userData.language.iso : 'us'" />
              <span style="padding-left: 5px">
                {{ userData.language ? userData.language.name : "English" }}
              </span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="language in filteredLanguages"
              :key="language.code"
              @click="changeLanguage(language)"
            >
              <flag :iso="language.iso" />
              <v-list-item-title>{{ language.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- avatar menu -->
        <v-menu bottom min-width="200px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon x-large v-on="on">
              <v-avatar>
                <img :src="user.photoURL" alt="profile picture" />
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar>
                  <img :src="user.photoURL" alt="profile picture" />
                </v-avatar>
                <h3 style="margin-top: 0.3em" v-if="user.displayName">
                  {{ user.displayName }}
                </h3>
                <h3 style="font-size: 0.9em; margin-top: 0.5em" v-else>{{ user.email }}</h3>
                <v-divider class="my-3"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                  v-for="(item, i) in userMenuItems"
                  :key="i"
                  :to="item.to"
                  :color="'primary'"
                >
                  {{ item.text }}
                </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text color="#F00" @click="doLogout">
                  {{ $t("menu.logout") }}
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-layout>
    </v-toolbar>
    <!-- <div class="page"></div> -->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>
    <div
      style="
        display: flex;
        flex-direction: column-reverse;
        position: absolute;
        left: 0;
        bottom: 5px;
      "
    >
      <v-snackbar
        style="position: relative; height: auto"
        v-for="item in snackbars"
        :key="item.uid"
        :value="true"
        :timeout="item.timeout + 100"
        bottom
        left
      >
        {{ item.text }}

        <template v-slot:action="{ attrs }">
          <v-btn :color="item.type" text v-bind="attrs" icon @click="removeSnackbar(item)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <v-progress-linear
          :value="item.progress"
          :color="item.type"
          height="2px"
          style="position: absolute; left: 0; bottom: 0"
        ></v-progress-linear>
      </v-snackbar>
    </div>
    <Dialog v-if="dialog.isOpen" />
    <ShareLesson v-if="shareLesson.isOpen" />
  </v-app>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import { mapActions, mapMutations, mapState } from "vuex";
import Flag from "vue-flag-icon/components/icon/Flag.vue";
import ShareLesson from "./components/ShareLesson.vue";

export default {
  name: "App",
  components: { Flag, ShareLesson },
  data() {
    return {
      backgroundRouteData: {
        "/": {
          angle: 20,
          color: "#222",
          duration: 1.2,
          direction: "normal",
          directionGrid: "normal",
        },
        "/login": {
          angle: 0,
          color: "#FF0",
          duration: 1.5,
          direction: "reverse",
          directionGrid: "normal-reverse",
        },
        "/licenses": {
          angle: -30,
          color: "#A730DF", // "#FF9500",
          duration: 2.5,
          direction: "normal",
          directionGrid: "reverse",
        },
        "/lessons": {
          angle: -10,
          color: "#006EFF",
          duration: 2.5,
          direction: "normal",
          directionGrid: "reverse",
        },
        "/home": {
          angle: 10,
          color: "#0F0", // "#7DF745",
          duration: 1.5,
          direction: "normal",
          directionGrid: "normal",
        },
        "/play": {
          angle: 45,
          color: "#000",
          duration: 3,
          scale: 1.5,
          direction: "normal",
          directionGrid: "normal",
        },
      },
      userMenu: false,
      userMenuItems: [
        {
          text: this.$t("menu.lessons"),
          to: "/home",
        },
        {
          text: this.$t("menu.licenses"),
          to: "/licenses",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: "user",
      userData: "userData",
      snackbars: "snackbars",
      dialog: "dialog",
      shareLesson: "shareLesson",
      languages: "languages",
    }),
    filteredLanguages() {
      return this.languages.filter((language) => language.hasTranslation);
    },
    routePath() {
      let path = this.$route.path;
      if (path.startsWith("/lessons/")) {
        path = "/lessons";
      }
      if (path.startsWith("/play/")) {
        path = "/play";
      }
      return path;
    },
  },
  methods: {
    ...mapActions({
      logout: "logout",
      onAuthStateChanged: "onAuthStateChanged",
      updateUserData: "updateUserData",
      removeSnackbar: "removeSnackbar",
      changeLanguage: "changeLanguage",
      initHistoryLength: "initHistoryLength",
    }),
    doLogout() {
      this.logout();
      this.$router.push("/");
    },
  },
  mounted() {
    this.onAuthStateChanged();
    if (this.user) {
      this.updateUserData();
    }
    window.root = this;
    // if on mobile, request fullscreen on tap
    if (this.$vuetify.breakpoint.smAndDown) {
      document.body.addEventListener("touchstart", () => {
        if (!document.fullscreenElement) {
          document.body.requestFullscreen();
        }
      });
    }
    this.initHistoryLength();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

:root {
  overflow: hidden;
}

body {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

html {
  width: 100%;
  height: 100%;
}

.toolbar {
  background-color: #ffffff47 !important;
  backdrop-filter: blur(8px);
  position: absolute !important;
  width: 100%;
  z-index: 999999;
}
.page {
  padding-top: 56px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
