<template>
  <div class="background" id="background">
    <div class="background-tiled-container-container">
      <div class="background-tiled-container">
        <div class="background-grid-container">
          <div class="background-grid"></div>
        </div>
        <div class="background-tiled"></div>
        <div class="background-tiled"></div>
        <div class="background-tiled"></div>
        <div class="background-tiled"></div>
        <div class="background-tiled"></div>
        <div class="background-tiled"></div>
        <div class="background-tiled-offset background-tiled-offset1"></div>
        <div class="background-tiled-offset background-tiled-offset2"></div>
      </div>
    </div>
    <div class="background-shadow"></div>
  </div>
</template>

<script>
export default {
  name: "Background",
  props: {
    angle: {
      type: Number,
      default: 0,
      required: false,
    },
    color: {
      type: String,
      default: "#055",
      required: false,
    },
    duration: {
      type: Number,
      default: 1,
      required: false,
    },
    scale: {
      type: Number,
      default: 1,
      required: false,
    },
    direction: {
      type: String,
      default: "normal",
      required: false,
    },
    directionGrid: {
      type: String,
      default: "normal",
      required: false,
    },
  },
  watch: {
    angle() {
      this.updateStyle();
    },
    color() {
      this.updateStyle();
    },
    duration() {
      this.updateStyle();
    },
    direction() {
      this.updateStyle();
    },
    directionGrid() {
      this.updateStyle();
    },
  },
  data() {
    return {
      r: null,
    };
  },
  mounted() {
    this.r = document.querySelector("#background");
    this.updateStyle();
  },
  methods: {
    lerp(a, b, t) {
      return a + (b - a) * t;
    },
    easeInCubic(t) {
      return t * t * t;
    },
    updateStyle() {
      const angle = this.angle || 0;
      const color = this.color || "#055";
      const direction = this.direction || "normal";
      const directionGrid = this.directionGrid || "normal";
      const duration = this.duration || 1;

      const angleModulo = ((angle % 90) + 90) % 90;
      const progress = Math.abs((angleModulo / 90) * 2 - 1);
      const scale = this.scale * this.lerp(1.5, 1, this.easeInCubic(progress));
      // set the value of the variable
      this.r.style.setProperty("--rotate", `${angle}deg`);
      this.r.style.setProperty("--scale", scale);
      this.r.style.setProperty("--background-color", color);
      this.r.style.setProperty("--animation-direction", direction);

      const directionGridParam = directionGrid.split("-");
      if (directionGridParam.length === 1) {
        directionGridParam.push(directionGridParam[0]);
      }
      if (directionGridParam[0] === directionGridParam[1]) {
        this.r.style.setProperty("--animation-grid", "slide-grid");
      } else {
        this.r.style.setProperty("--animation-grid", "slide-grid-reverse");
      }
      this.r.style.setProperty("--animation-direction-grid", directionGridParam[0]);
      this.r.style.setProperty("--anim-duration", `${duration}s`);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  font-family: sans-serif;
  background-color: black;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* inner shadow */
  --bg-width: 200px;
  --bg-height: 320px;
  --max-offset: 120px;
  --anim-duration: 1s;
  --anim-multiplier-1: 1.5;
  --anim-multiplier-2: 2;
  --rotate: 0deg;
  --extra-offset: max(0px, calc((100vw - 100vh) / 2));
  --scale: 1;
  --background-color: green;
  --grid-size: 32px;
  --transition-duration: 0.23s;
  --color-transition-duration: 0.17s;
  --animation-direction: normal;
  --animation-direction-grid: normal;
  --shadow-color: #444;
  --animation-grid: slide-grid;
}
/* when portrait */
@media screen and (orientation: portrait) {
  .background {
    --extra-offset: max(0px, calc((100vh - 100vw) / 2));
  }
}
/* when screen is small */
@media screen and (max-width: 800px), screen and (max-height: 800px) {
  .background {
    --extra-offset: max(1vh, calc((100vh - 100vw) / 2));
  }
  @media screen and (orientation: portrait) {
    .background {
      --extra-offset: max(1vw, calc((100vw - 100vh) / 2));
    }
  }
}
/* when screen is small */
@media screen and (max-width: 389px), screen and (max-height: 400px) {
  .background {
    --extra-offset: max(-5vw, calc((100vh - 100vw) / 2));
  }
  @media screen and (orientation: portrait) {
    .background {
      --extra-offset: max(-5vh, calc((100vw - 100vh) / 2));
    }
  }
}
/* when screen is small */
@media screen and (max-width: 600px) and (max-height: 600px) {
  .background {
    --extra-offset: max(-5vw, calc((100vh - 100vw) / 2));
  }
  @media screen and (orientation: portrait) {
    .background {
      --extra-offset: max(-5vh, calc((100vw - 100vh) / 2));
    }
  }
}

.background-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* inner shadow */
  box-shadow: inset 0 0 180px 50px #0000009c;
}

.background-grid-container {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  overflow: hidden;
  filter: brightness(0.3);
  transform: rotate3d(0, 0, 1, calc(-0.9 * var(--rotate)));
  transition: transform var(--transition-duration);
  -moz-transition: transform var(--transition-duration);
  -webkit-transition: transform var(--transition-duration);
}

.background-grid {
  background-image: url("../assets/clumo grid.png");
  background-repeat: repeat;
  position: absolute;
  top: calc(-1 * var(--grid-size));
  left: calc(-1 * var(--grid-size));
  width: calc(100% + var(--grid-size));
  height: calc(100% + var(--grid-size));
  overflow: hidden;
  animation: var(--animation-grid) var(--anim-duration) linear infinite;
  animation-direction: var(--animation-direction-grid);
}

.background-tiled-container {
  position: absolute;
  /* center div */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  overflow: hidden;
  transform: rotate3d(0, 0, 1, var(--rotate)) scale3d(var(--scale), var(--scale), 1);
  transition: transform var(--transition-duration);
  -moz-transition: transform var(--transition-duration);
  -webkit-transition: transform var(--transition-duration);
}

.background-tiled-container-container {
  position: absolute;
  /* center div */
  top: 50%;
  left: 50%;
  width: max(100vh, 100vw);
  height: max(100vh, 100vw);
  overflow: hidden;
  transform: translate(-50%, -50%);
  background-color: var(--background-color);
  transition: background-color linear var(--color-transition-duration);
  -moz-transition: background-color linear var(--color-transition-duration);
  -webkit-transition: background-color linear var(--color-transition-duration);
}

.background-tiled {
  background-image: url("../assets/clumo bg rotated.png");
  background-repeat: repeat-y;
  background-position: center;
  position: absolute;
  width: var(--bg-width);
  height: calc(100% + var(--bg-height));
  animation: slide-y var(--anim-duration) linear infinite;
  animation-direction: var(--animation-direction);
  /* filter: drop-shadow(20px 0px 10px var(--shadow-color)); */
}

.background-tiled-offset {
  background-color: white;
  background-repeat: repeat-y;
  background-position: center;
  position: absolute;
  width: calc(var(--bg-width) * 5);
  height: 100%;
}

.background-tiled:nth-child(2n) {
  top: calc(var(--bg-height) * -1);
}

.background-tiled:nth-child(2n + 1) {
  top: calc(var(--bg-height) * -1);
  transform: rotate3d(0, 0, 1, 180deg);
  animation-name: slide-y-rotated;
}

.background-tiled:nth-child(2) {
  left: var(--extra-offset);
}

.background-tiled:nth-child(3) {
  right: var(--extra-offset);
}

.background-tiled:nth-child(4) {
  left: calc(var(--extra-offset) + var(--max-offset) / 2);
  background-image: url("../assets/clumo bg flipped.png");
  background-position-y: 100px;
  filter: opacity(0.5) /*  drop-shadow(20px 0px 10px var(--shadow-color)) */;
  animation-duration: calc(var(--anim-duration) * var(--anim-multiplier-1));
}

.background-tiled:nth-child(5) {
  right: calc(var(--extra-offset) + var(--max-offset) / 2);
  background-image: url("../assets/clumo bg flipped.png");
  background-position-y: 100px;
  filter: opacity(0.5) /*  drop-shadow(20px 0px 10px var(--shadow-color)) */;
  animation-duration: calc(var(--anim-duration) * var(--anim-multiplier-1));
}

.background-tiled:nth-child(6) {
  left: calc(var(--extra-offset) + var(--max-offset));
  background-position-y: 0px;
  filter: opacity(0.3) /*  drop-shadow(20px 0px 10px var(--shadow-color)) */;
  animation-duration: calc(var(--anim-duration) * var(--anim-multiplier-2));
}

.background-tiled:nth-child(7) {
  right: calc(var(--extra-offset) + var(--max-offset));
  background-position-y: 0px;
  filter: opacity(0.3) /*  drop-shadow(20px 0px 10px var(--shadow-color)) */;
  animation-duration: calc(var(--anim-duration) * var(--anim-multiplier-2));
}

.background-tiled-offset1 {
  left: calc(var(--extra-offset) - var(--bg-width) * 5 + 1px);
}

.background-tiled-offset2 {
  right: calc(var(--extra-offset) - var(--bg-width) * 5 + 1px);
}

@keyframes slide-y {
  0% {
    transform: translate3D(0, 0, 0);
  }
  100% {
    transform: translate3D(0, var(--bg-height), 0);
  }
}

@keyframes slide-y-rotated {
  0% {
    transform: rotate3d(0, 0, 1, 180deg) translate3D(0, 0, 0);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg) translate3D(0, calc(var(--bg-height) * -1), 0);
  }
}

@keyframes slide-grid {
  0% {
    transform: translate3D(0, 0, 0);
  }
  100% {
    transform: translate3D(var(--grid-size), var(--grid-size), 0);
  }
}

@keyframes slide-grid-reverse {
  0% {
    transform: translate3D(0, 0, 0);
  }
  100% {
    transform: translate3D(var(--grid-size), calc(-1 * var(--grid-size)), 0);
  }
}
</style>
