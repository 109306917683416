<template>
  <!-- activity indicator -->
  <div>
    <!-- center -->
    <v-progress-circular
      indeterminate
      color="primary"
      style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
    ></v-progress-circular>
    <qrcode-stream ref="qrcodeStream" :track="paintOutline" @decode="onScanSuccess" @init="onInit">
      <!-- fullscreen button icon -->
      <div style="right: 0; bottom: 0; position: absolute">
        <v-btn color="primary" height="40" icon dark @click="toggleFullscreen">
          <v-icon v-if="fullscreen">mdi-fullscreen</v-icon>
          <v-icon v-else>mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn color="primary" height="40" icon dark @click="switchCamera">
          <v-icon>mdi-camera-switch</v-icon>
        </v-btn>
      </div>
    </qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "QrCodeScanner",
  props: {},
  mounted() {},
  data: () => ({
    fullscreen: false,
    camera: "rear",
    noRearCamera: false,
    noFrontCamera: false,
    error: false,
  }),
  components: {
    QrcodeStream,
  },
  methods: {
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
      if (this.fullscreen) {
        document.getElementsByClassName("qrcode-stream-wrapper")[0].classList.add("fullscreen");
      } else {
        document.getElementsByClassName("qrcode-stream-wrapper")[0].classList.remove("fullscreen");
      }
    },
    switchCamera() {
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
        default:
          this.camera = "rear";
          break;
      }
    },
    onScanSuccess(decodedText) {
      this.$emit("decode", decodedText);
    },
    paintOutline(detectedCodes, ct) {
      const ctx = ct;
      detectedCodes.forEach((detectedCode) => {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        otherPoints.forEach((point) => {
          ctx.lineTo(point.x, point.y);
        });
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      });
    },
    async onInit(promise) {
      try {
        await promise;
        this.toggleFullscreen();
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }

        const triedFrontCamera = this.camera === "front";
        const triedRearCamera = this.camera === "rear";

        const cameraMissingError = error.name === "OverconstrainedError";

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true;
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true;
        }

        console.error(error);
        if (this.error) {
          this.$emit("error", this.error);
        }
        this.toggleFullscreen();
      }
    },
  },
};
</script>

<style>
.fullscreen {
  position: fixed !important;
  z-index: 1000 !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
</style>
