import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    home: {
      noLessons: "You have no lessons yet",
      addLesson: "Add lesson",
      fromCode: "From code",
      lessons: "Lessons",
      name: "Name",
      actions: "Actions",
      languages: "Languages",
      score: "Score",
      id: "ID",
      owner: "Owner",
    },
    licenses: {
      purchase: "Purchase licenses",
      count: "Count",
      countPlaceholder: "Enter the number of licenses you wish to buy",
      total: "Total",
      checkout: "Checkout",
      activeLicenses: "Your active licenses",
      activationDate: "Activation Date",
      status: "Status",
      buyer: "Buyer",
      licenseId: "License ID",
      enterLicenseId: "Enter license ID",
      creationDate: "Creation Date",
      user: "User",
      activate: "Activate",
      activatedOn: "Activated on",
      copyId: "Copy ID",
      boughtLicenses: "Your bought licenses",
      showUsedLicenses: "Show used licenses",
      useLicense: "Use license",
      cannotActivateMessage: "You already have an active license",
      active: "Active",
      expired: "Expired",
      unused: "Unused",
      noLicenses: "You have no licenses yet",
      activatingText: "Activating license...",
      buyingText: (ctx) => {
        const nb = parseInt(ctx.named("nb"), 10);
        return `Buying ${nb} license${nb > 1 ? "s" : ""}`;
      },
    },
    play: {
      loading: "Loading game...",
    },
    rules: {
      required: "Required.",
      min: "You cannot buy less than 1 license.",
      max: "You cannot buy more than 999 licenses.",
      number: "Must be a number.",
      integer: "Must be a valid integer.",
    },
    menu: {
      lessons: "Lessons",
      licenses: "Licenses",
      logout: "Logout",
    },
    login: {
      google: "Sign in with Google",
      email: "Sign in with email",
    },
    lessons: {
      name: "Name",
      play: "Play",
      share: "Share",
      words: "Words",
      source: "Source",
      translation: "Translation",
      cantPlay: "You need to have at least 5 words to play this lesson",
      users: "Users",
      score: "Score",
    },
    dialog: {
      deleteLesson: {
        title: "Delete lesson",
        message: "Are you sure you want to delete this lesson?",
        okText: "Yes",
        cancelText: "No",
        title2: "Are you sure?",
        message2: "Other users have this lesson. Deleting it will remove it from their library.",
        okText2: "Delete for everyone",
        cancelText2: "Cancel",
      },
    },
    tooltip: {
      edit: "Edit",
      delete: "Delete",
      duplicate: "Duplicate",
      play: "Play",
      share: "Share",
    },
    shareLesson: {
      copyId: "Copy ID",
      downloadCode: "Download QR Code",
      close: "Close",
    },
    fromCode: {
      code: "ID",
      qrCode: "QR Code",
      file: "File",
      fromCode: "Enter Code",
      scanCode: "Scan QR Code with camera",
      scanFile: "Scan QR Code from file",
      load: "Load",
      error: "Invalid QR Code",
      errorServer: "Error loading lesson",
      errorScan: "Error scanning QR Code",
      startScan: "Start scan",
      stopScan: "Stop scan",
    },
    snackbars: {
      shareLesson: {
        copied: "Copied!",
        preparing: "Preparing download...",
        downloaded: "Downloaded!",
      },
      licenses: {
        copied: "License ID copied to clipboard",
        activated: "License activated",
        internalError: "Internal error when activating license",
      },
      lessons: {
        created: "Lesson created",
        duplicated: "Lesson duplicated",
        saved: "Lesson saved",
        deleted: "Lesson deleted",
      },
      language: {
        changed: "Language changed",
      },
      score: {
        saved: "Score saved",
        error: "Error saving score",
        saving: "Saving score...",
      },
    },
  },
};

export default new VueI18n({
  locale: "en", // set locale
  messages, // set locale messages
});
