import Vue from "vue";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import * as firebaseui from "firebaseui";
import FlagIcon from "vue-flag-icon";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import "./plugins/customComponents";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "@stripe/stripe-js";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDaxxXc31XseJlBsDPwX3hA-q8HWdLqKl8",
  authDomain: "clumo-7f5fc.firebaseapp.com",
  projectId: "clumo-7f5fc",
  storageBucket: "clumo-7f5fc.appspot.com",
  messagingSenderId: "356518543283",
  appId: "1:356518543283:web:38abb13c3f4e3440ded5c6",
  measurementId: "G-QM9G7WJBPE",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const ui = new firebaseui.auth.AuthUI(firebase.auth());
console.log(app);

Vue.config.productionTip = false;
Vue.prototype.$firebase = firebase;
Vue.prototype.$firebaseui = ui;
Vue.prototype.$firestore = firebase.firestore();
Vue.prototype.$functions = firebase.functions();
Vue.prototype.$vueI18n = i18n;

Vue.use(FlagIcon);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
