<template>
  <v-dialog
    dark
    v-model="show"
    :persistent="shareLesson.persistent"
    :max-width="mobileMode ? '100%' : '60vw'"
    width="fit-content"
    elevation="20"
    overlay-opacity="0.5"
    overlay-color="black"
    @click:outside="maybeOnCancel"
  >
    <v-card>
      <v-card-title style="display: flex; align-items: center; justify-content: center">
        {{ shareLesson.id }}</v-card-title
      >
      <div style="display: flex; align-items: center; justify-content: center">
        <div
          id="qrcode"
          style="
            width: fit-content;
            padding: 20px;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        ></div>
      </div>
      <v-card-actions
        :style="
          mobileMode
            ? 'justify-content: center !important; flex-wrap: wrap;'
            : 'justify-content: flex-end'
        "
      >
        <v-btn color="primary" style="margin-top: 5px; margin-left: 8px" @click="copyId">{{
          $t("shareLesson.copyId")
        }}</v-btn>
        <v-btn color="primary" style="margin-top: 5px" @click="downloadCode">{{
          $t("shareLesson.downloadCode")
        }}</v-btn>
        <v-btn color="secondary" style="margin-top: 5px" @click="close">{{
          $t("shareLesson.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { toBlob } from "html-to-image";
import { saveAs } from "file-saver";

export default {
  name: "ShareLesson",
  props: {},
  mounted() {
    this.$nextTick(() => {
      this.qrcode = new window.QRCode(document.getElementById("qrcode"), {
        text: this.shareLesson.id,
        width: 256,
        height: 256,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: window.QRCode.CorrectLevel.H,
      });
    });
  },
  beforeDestroy() {
    this.qrcode.clear();
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {
    ...mapState({
      shareLesson: "shareLesson",
    }),
    mobileMode() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapMutations({
      closeShareLesson: "closeShareLesson",
    }),
    ...mapActions({
      createSnackbar: "createSnackbar",
    }),
    copyId() {
      navigator.clipboard.writeText(this.shareLesson.id);
      this.createSnackbar({
        text: this.$t("snackbars.shareLesson.copied"),
        type: "success",
      });
    },
    downloadCode() {
      toBlob(document.querySelector("div#qrcode")).then((blob) => {
        if (window.saveAs) {
          window.saveAs(blob, `qrCode-lesson-${this.shareLesson.id}.png`);
        } else {
          saveAs(blob, `qrCode-lesson-${this.shareLesson.id}.png`);
        }
        this.createSnackbar({
          text: this.$t("snackbars.shareLesson.downloaded"),
          type: "success",
        });
      });
      this.createSnackbar({
        text: this.$t("snackbars.shareLesson.preparing"),
        type: "info",
      });
    },
    close() {
      this.show = false;
      setTimeout(() => {
        this.closeShareLesson();
      }, 300);
    },
    maybeOnCancel() {
      if (this.shareLesson.persistent) return;
      this.close();
    },
  },
};
</script>

<style>
#qrcode img {
  max-width: 100%;
}
</style>
