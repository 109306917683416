<template>
  <div v-if="!shown">
    <v-btn icon dark @click="shown = true">
      <v-icon>mdi-eye</v-icon>
    </v-btn>
  </div>
  <div
    v-else
    style="
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
    "
  >
    <slot></slot>
    <v-btn icon dark @click="shown = false">
      <v-icon>mdi-eye-off</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Spoiler",
  data() {
    return {
      shown: false,
    };
  },
  computed: {
    children() {
      return this.$slots.default;
    },
  },
  mounted() {},
  methods: {},
};
</script>
