<template>
  <CenterView fullScreen>
    <Card
      fullScreenIfMobile
      useStyle="max-width: 80vw; width: 80vw; max-height: 80vh; margin-top: 5vh"
    >
      <CenterView v-if="!isUserLoaded || loading" style="padding: 10px; overflow-y: none">
        <v-progress-circular
          style="margin: 10px"
          size="50"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </CenterView>
      <CenterView v-else>
        <div class="home-noLicense" v-if="!hasActiveLicense">
          <span style="color: white">
            {{ noLicenseMessage }}
          </span>
          <br />
          <br />
          <v-btn color="primary" dark @click="buy">Go to licenses page</v-btn>
        </div>
        <div class="home-hasLicense" v-else>
          <div>
            <v-btn dark color="primary" style="width: 50%" @click="newLesson">
              <v-icon>mdi-plus</v-icon>
              <span>{{ $t("home.addLesson") }}</span>
            </v-btn>
            <v-btn dark color="primary" style="width: 50%" @click="fromCode">
              <v-icon>mdi-qrcode-scan</v-icon>
              <span style="padding-left: 5px">{{ $t("home.fromCode") }}</span>
            </v-btn>
            <v-list
              dark
              style="padding-left: 10px; padding-right: 10px; border-radius: 10px; width: 100%"
            >
              <v-simple-table v-if="hasAnyLesson">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">{{ $t("home.name") }}</th>
                      <th class="text-center">{{ $t("home.actions") }}</th>
                      <th class="text-center">{{ $t("home.languages") }}</th>
                      <th class="text-center">{{ $t("home.score") }}</th>
                      <th class="text-center">{{ $t("home.id") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(lesson, index) in lessonsData"
                      :key="index"
                      @click="editLesson(lesson)"
                    >
                      <td>{{ lesson.name }}</td>
                      <td
                        style="
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <div
                          v-for="(action, index) in actions"
                          :key="index"
                          :style="action.divider ? `height: 100%; padding: 5px;` : ''"
                        >
                          <v-divider dark vertical v-if="action.divider"></v-divider>
                          <v-tooltip top v-else-if="action.condition(lesson)" :color="action.color">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                dark
                                :color="action.color"
                                @click.stop="action.handler(lesson)"
                              >
                                <v-icon>{{ action.icon }}</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t(action.tooltip) }}</span>
                          </v-tooltip>
                        </div>
                      </td>
                      <td>
                        <div style="display: flex; justify-content: center; padding-top: 10px">
                          <div style="display: flex; flex-direction: column; align-items: center">
                            <flag :iso="languages.find((l) => l.code === lesson.language1).iso" />

                            <span>{{ lesson.language1 }}</span>
                          </div>
                          <v-icon>mdi-arrow-right</v-icon>
                          <div style="display: flex; flex-direction: column; align-items: center">
                            <flag :iso="languages.find((l) => l.code === lesson.language2).iso" />

                            <span>{{ lesson.language2 }}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <!-- {{ (userData && userData.scores[lesson.id]) || 0 }} /
                        {{ (lesson && lesson.words && lesson.words.length) || 0 }} -->

                        {{ scoreToPercents(userData, lesson) }}%
                      </td>
                      <td v-on:click.stop="">
                        <Spoiler v-if="lesson.owner === userData.id">{{ lesson.id }}</Spoiler>
                        <!-- tooltip -->
                        <v-tooltip v-else top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              style="
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;
                              "
                            >
                              <v-avatar
                                style="margin-left: 5px"
                                size="30"
                                color="#fff"
                                class="mr-2"
                              >
                                <img :src="lesson.ownerPhoto" alt="profile picture" />
                              </v-avatar>
                              {{ lesson.ownerName }}
                            </div>
                          </template>
                          <span>{{ $t("home.owner") }}</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-else dark style="margin: 10px">
                <v-progress-circular
                  v-if="!isUserLoaded"
                  indeterminate
                  size="40"
                  style="margin: 0 auto"
                />
                <span v-else>{{ $t("home.noLessons") }}</span>
              </div>
            </v-list>
          </div>
        </div>
      </CenterView>
    </Card>
  </CenterView>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions, mapMutations } from "vuex";
import Spoiler from "../components/Spoiler.vue";

export default {
  name: "Home",
  components: { Spoiler },
  mounted() {},
  data() {
    return {
      noLicenseMessage: "You have no license. Please buy one.",
      loading: false,
      actions: [
        {
          tooltip: "tooltip.edit",
          icon: "mdi-pencil",
          handler: (lesson) => {
            this.editLesson(lesson);
          },
          condition: (lesson) => lesson.owner === this.userData.id,
        },
        {
          tooltip: "tooltip.share",
          icon: "mdi-share",
          handler: (lesson) => {
            this.shareLesson(lesson);
          },
          condition: (lesson) => lesson.owner === this.userData.id,
        },
        {
          tooltip: "tooltip.play",
          icon: "mdi-play",
          handler: (lesson) => {
            this.playLesson(lesson);
          },
          condition: (lesson) => lesson.words.length >= 5,
        },
        {
          tooltip: "tooltip.duplicate",
          icon: "mdi-content-copy",
          handler: (lesson) => {
            this.doDuplicateLesson(lesson);
          },
          condition: (lesson) => lesson.owner === this.userData.id,
        },
        {
          divider: true,
        },
        {
          tooltip: "tooltip.delete",
          icon: "mdi-delete",
          handler: (lesson) => {
            this.doDeleteLesson(lesson);
          },
          condition: (lesson) => true,
          color: "error",
        },
      ],
    };
  },
  watch: {
    isUserLoaded(newValue) {
      // if (!newValue) {
      //   this.$router.push("/");
      // }
    },
  },
  computed: {
    ...mapState({
      user: "user",
      userData: "userData",
      lessonsData: "lessonsData",
      licensesData: "licensesData",
      isUserLoaded: "isUserLoaded",
      languages: "languages",
    }),
    hasActiveLicense() {
      return (
        this.licensesData && Object.values(this.licensesData).some((license) => license.active)
      );
    },
    hasAnyLesson() {
      return this.lessonsData && Object.values(this.lessonsData).length > 0;
    },
  },
  methods: {
    ...mapMutations({
      shareLesson: "shareLesson",
    }),
    ...mapActions({
      logout: "logout",
      createLesson: "createLesson",
      duplicateLesson: "duplicateLesson",
      deleteLesson: "deleteLesson",
      confirmDialog: "confirmDialog",
    }),
    buy() {
      this.$router.push("/buy");
    },
    async newLesson() {
      this.loading = true;
      const lessonId = await this.createLesson();
      // this.loading = false;
      this.$router.push(`/lessons/${lessonId}`);
    },
    editLesson(lesson) {
      if (lesson.owner !== this.userData.id) return;
      this.$router.push({
        name: "Lesson",
        params: {
          id: lesson.id,
        },
      });
    },
    playLesson(lesson) {
      this.$router.push({
        name: "Play",
        params: {
          id: lesson.id,
        },
      });
    },
    async doDuplicateLesson(lesson) {
      this.loading = true;
      const lessonId = await this.duplicateLesson({ id: lesson.id });
      // this.loading = false;
      this.$router.push(`/lessons/${lessonId}`);
    },
    doDeleteLesson(lesson) {
      const deleteLesson = async () => {
        this.loading = true;
        await this.deleteLesson({ id: lesson.id });
        this.loading = false;
      };
      this.confirmDialog({
        title: this.$t("dialog.deleteLesson.title"),
        message: this.$t("dialog.deleteLesson.message"),
        type: "error",
        persistent: false,
        okText: this.$t("dialog.deleteLesson.okText"),
        cancelText: this.$t("dialog.deleteLesson.cancelText"),
        onOk: async () => {
          if (lesson.owner === this.userData.id && lesson.users && lesson.users.length > 0) {
            setTimeout(() => {
              this.confirmDialog({
                title: this.$t("dialog.deleteLesson.title2"),
                message: this.$t("dialog.deleteLesson.message2"),
                type: "error",
                persistent: false,
                okText: this.$t("dialog.deleteLesson.okText2"),
                cancelText: this.$t("dialog.deleteLesson.cancelText2"),
                async onOk() {
                  await deleteLesson();
                },
              });
            }, 400);
          } else {
            await deleteLesson();
          }
        },
      });
    },
    fromCode() {
      this.$router.push("/from-code");
    },
    scoreToPercents(userData, lesson) {
      const score = (userData && userData.scores[lesson.id]) || 0;
      const wordLength = (lesson && lesson.words && lesson.words.length) || 0;

      if (wordLength === 0) {
        return 0;
      }
      if (typeof score !== "number") {
        return 0;
      }
      const percent = Math.round((score / wordLength) * 100);
      return percent > 100 ? 100 : percent;
    },
  },
};
</script>

<style>
.home-hasLicense {
  width: 100%;
  height: 100%;
}
</style>
