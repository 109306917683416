import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Buy from "../views/Buy.vue";
import Login from "../views/Login.vue";
import Lesson from "../views/Lesson.vue";
import Play from "../views/Play.vue";
import FromCode from "../views/FromCode.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/licenses",
    name: "Licenses",
    component: Buy,
  },
  {
    path: "/buy",
    redirect: "/licenses",
  },
  {
    path: "/lessons/:id",
    name: "Lesson",
    component: Lesson,
  },
  {
    path: "/play/:id",
    name: "Play",
    component: Play,
  },
  {
    path: "/from-code",
    name: "FromCode",
    component: FromCode,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let userLoaded = false;
function getCurrentUser(auth) {
  const firebase = Vue.prototype.$firebase;
  return new Promise((resolve, reject) => {
    if (userLoaded) {
      resolve(firebase.auth().currentUser);
    }
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      userLoaded = true;
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

router.beforeEach(async (to, from, next) => {
  const user = await getCurrentUser();
  if (
    // make sure the user is authenticated
    !user &&
    to.path !== "/"
  ) {
    next({
      name: "Login",
      replace: true,
    });
  } else {
    next();
  }
});

// router.beforeEach(beforeEach);

export default router;
