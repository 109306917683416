<template>
  <CenterView>
    <div class="login" v-if="!user">
      <div v-if="useFirebaseUi" id="firebaseui-auth-container" />
      <v-btn v-else @click="loginWithGoogle" dark color="success">Login</v-btn>
    </div>
    <div class="home" v-else>
      <!-- activity indicator loading -->
      <v-progress-circular indeterminate color="primary" size="100" style="margin: 0 auto" />
    </div>
  </CenterView>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      useFirebaseUi: true,
    };
  },
  mounted() {
    if (this.isUserLoaded) this.$router.push("/home");
    else this.initFirebaseUi();
  },
  computed: {
    ...mapState({
      isUserLoaded: "isUserLoaded",
      user: "user",
    }),
  },
  watch: {
    isUserLoaded(newValue) {
      if (newValue) {
        this.$router.push("/home");
      }
    },
  },
  methods: {
    ...mapActions({
      loginWithGoogle: "loginWithGoogle",
    }),
    initFirebaseUi() {
      if (!this.useFirebaseUi) return;
      if (!document.getElementById("firebaseui-auth-container")) return;

      const ui = this.$firebaseui;
      window.ui = ui;
      ui.start("#firebaseui-auth-container", {
        signInOptions: [
          // List of OAuth providers supported.
          this.$firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          {
            provider: this.$firebase.auth.EmailAuthProvider.PROVIDER_ID,
            signInMethod: this.$firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
            forceSameDevice: false,
          },
        ],
        signInFlow: "popup",
        // redirect to home URL and construct the URL with the current domain
        signInSuccessUrl: `${window.location.origin}/home`,
        // Other config options...
      });
    },
  },
};
</script>
