<template>
  <CenterView fullScreen>
    <Card
      fullScreenIfMobile
      useStyle="max-width: 80vw; width: 80vw; max-height: 80vh; margin-top: 5vh"
    >
      <CenterView v-if="isUserLoaded && !loading && !isOut" style="overflow-y: none">
        <!-- textarea for lesson code  -->
        <div v-if="startedScan">
          <v-btn color="primary" height="40" dark @click="stopScan">
            {{ $t("fromCode.stopScan") }}
          </v-btn>
          <QrCodeScanner @decode="onDecode" @error="errorScan" />
        </div>
        <div v-else style="width: 100%">
          <qrcode-drop-zone
            style="width: 100%"
            @decode="onDecode"
            @dragover="onDragOver"
            @init="logErrors"
          >
            <div class="drop-area" :class="{ dragover: dragover }">
              <!-- ==================TEXT INPUT========================= -->
              <CenterView>
                <div
                  class="header white--text text-left"
                  style="
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                  "
                >
                  <h3>{{ $t("fromCode.fromCode") }}</h3>
                  <div style="flex-direction: row; display: flex; flex-wrap: wrap">
                    <v-text-field
                      v-model="id"
                      :label="$t('fromCode.code')"
                      outlined
                      filled
                      dense
                      dark
                      hide-details=""
                    ></v-text-field>
                    <v-btn color="primary" height="40" dark @click="load">
                      {{ $t("fromCode.load") }}
                    </v-btn>
                  </div>
                </div>

                <!-- ===================================================== -->

                <div
                  v-if="hasCamera"
                  class="header white--text text-left"
                  style="
                    margin-top: 20px;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                  "
                >
                  <h3>{{ $t("fromCode.scanCode") }}</h3>
                  <v-btn color="primary" height="40" dark @click="startScan">
                    {{ $t("fromCode.startScan") }}
                  </v-btn>
                </div>
                <div
                  v-if="hasCamera"
                  class="header white--text text-left"
                  style="
                    margin-top: 20px;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                  "
                >
                  <h3>{{ $t("fromCode.scanFile") }}</h3>
                </div>
                <qrcode-capture @decode="onDecode" />
              </CenterView>
            </div>
          </qrcode-drop-zone>
        </div>
      </CenterView>
      <CenterView v-else>
        <v-progress-circular
          style="margin: 10px"
          size="50"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </CenterView>
    </Card>
  </CenterView>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import QrScanner from "qr-scanner";

export default {
  name: "Lesson",
  data() {
    return {
      loading: false,
      isOut: false,
      id: "",
      file: null,
      qrScanner: null,
      hasCamera: false,
      startedScan: false,
      dragover: false,
    };
  },
  computed: {
    ...mapState({
      user: "user",
      userData: "userData",
      isUserLoaded: "isUserLoaded",
    }),
  },
  watch: {
    file() {
      if (this.file) {
        QrScanner.scanImage(this.file, { returnDetailedScanResult: true })
          .then((result) => {
            this.id = result.data;
            this.load();
          })
          .catch((error) => {
            console.log(error);
            this.file = null;
            this.createSnackbar({
              text: this.$t("fromCode.error"),
              type: "error",
            });
          });
      }
    },
  },
  mounted() {
    QrScanner.hasCamera().then((hasCamera) => {
      this.hasCamera = hasCamera;
    });
  },
  beforeDestroy() {
    if (this.qrScanner) {
      this.qrScanner.stop();
      this.qrScanner.destroy();
    }
  },
  components: {
    QrcodeDropZone,
    QrcodeCapture,
  },
  methods: {
    ...mapActions(["getUser", "createSnackbar", "getLesson"]),
    ...mapMutations({}),
    load() {
      this.loading = true;
      this.getLesson(this.id)
        .then((lesson) => {
          this.$router.push("/home");
        })
        .catch((error) => {
          console.log(error);
          this.createSnackbar({
            text: this.$t("fromCode.errorServer"),
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
          this.startedScan = false;
        });
    },
    onScan(decodedText, decodedResult) {
      // handle the message here :)
      console.log(decodedText);
      this.id = decodedText;
      this.load();
    },
    onDecode(decodedString) {
      this.id = decodedString;
      this.load();
    },
    async onDecodeDropZone(promise) {
      try {
        const { content } = await promise;

        this.result = content;
        this.error = null;
      } catch (error) {
        if (error.name === "DropImageFetchError") {
          this.error = "Sorry, you can't load cross-origin images :/";
        } else if (error.name === "DropImageDecodeError") {
          this.error = "Ok, that's not an image. That can't be decoded.";
        } else {
          this.error = `Ups, what kind of error is this?! ${error.message}`;
        }
      }
    },
    startScan() {
      this.startedScan = true;
    },
    stopScan() {
      this.startedScan = false;
    },
    errorScan(error) {
      console.log(error);
      this.createSnackbar({
        text: this.$t("fromCode.errorScan"),
        type: "error",
      });
      this.stopScan();
    },

    onDragOver(isDraggingOver) {
      this.dragover = isDraggingOver;
    },
    logErrors(promise) {
      promise.catch(console.error);
    },
  },
};
</script>

<style>
.drop-area {
  text-align: center;
  font-weight: bold;
  padding: 20px;
  transition: border-color 0.3s ease;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 2px dashed;
  border-color: #000;
}

.dragover {
  border-color: #ccc;
}
</style>
