import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import { loadStripe } from "@stripe/stripe-js";
import { nanoid } from "nanoid";

const STRIPE_PUBLISHABLE_KEY =
  "pk_live_51KGPWbDGUgtZ68AHBMPK4A80JHrUbRdKKADK60DeZcJgHcMkE4REeU5R2jvpGVZmGV4rNioLTQC10z1z5Rj9pdz400R7tRvInX";

// if (process.env.NODE_ENV === "development") {
//   STRIPE_PUBLISHABLE_KEY =
//   "pk_test_51KGPWbDGUgtZ68AHS1c1QAn6vxPMAhisEzO1myR4s3iQYPXaTkmMjz8R56CuOhG6yxlfBI9xLRIruC9cq0o726uD0019ufCHUB";
// }

let stripe;

const stripeWaitList = [];
function maybeWaitForStripe(fn) {
  if (stripe) {
    fn();
  } else {
    stripeWaitList.push(fn);
  }
}

loadStripe(STRIPE_PUBLISHABLE_KEY).then((res) => {
  stripe = res;
  stripeWaitList.forEach((fn) => fn());
  stripeWaitList.length = 0;
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    languages: [
      {
        name: "English",
        iso: "gb",
        code: "en",
        hasTranslation: true,
      },
      {
        name: "Hrvatski",
        iso: "hr",
        code: "hr",
        hasTranslation: true,
      },
      {
        name: "Česky",
        iso: "cz",
        code: "cs",
        hasTranslation: true,
      },
      {
        name: "Français",
        iso: "fr",
        code: "fr",
        hasTranslation: true,
      },
      {
        name: "српски",
        iso: "rs",
        code: "sr",
        hasTranslation: true,
      },
      {
        name: "Slovenščina",
        iso: "si",
        code: "sl",
        hasTranslation: true,
      },
      {
        name: "Bosanski",
        iso: "ba",
        code: "bs",
        hasTranslation: true,
      },
      {
        name: "Afrikaans",
        iso: "za",
        code: "af",
        hasTranslation: true,
      },
      {
        name: "Other",
        iso: "xx",
        code: "xx",
        hasTranslation: false,
      },
    ],
    isUserLoaded: false,
    user: null,
    userData: null,
    lessonsData: null,
    licensesData: null,
    boughtlicensesData: null,
    unsubscribeToUser: null,
    snackbars: [],
    dialog: {
      isOpen: false,
    },
    shareLesson: {
      isOpen: false,
    },
  },
  mutations: {
    setUser(state, payload) {
      console.log("setUser", payload);
      state.user = payload;
    },
    setUserData(state, payload) {
      console.log("setUserData", payload);
      state.userData = payload;
    },
    setLessonsData(state, payload) {
      console.log("setLessonsData", payload);
      state.lessonsData = payload;
    },
    setLesson(state, payload) {
      console.log("setLesson", payload);
      const id = payload.id;
      const newLesson = payload.lesson;
      state.lessonsData = {
        ...state.lessonsData,
        [id]: newLesson,
      };
    },
    removeLesson(state, payload) {
      console.log("removeLesson", payload);
      const id = payload.id;
      delete state.lessonsData[id];
    },
    setLicensesData(state, payload) {
      console.log("setLicesnsData", payload);
      state.licensesData = payload;
    },
    setBoughtLicensesData(state, payload) {
      console.log("setBoughtLicensesData", payload);
      state.boughtlicensesData = payload;
    },
    setIsUserLoaded(state, payload) {
      console.log("setIsUserLoaded", payload);
      state.isUserLoaded = payload;
    },
    setUnsubscribeToUser(state, payload) {
      console.log("setUnsubscribeToUser", payload);
      state.unsubscribeToUser = payload;
    },
    createSnackbar(state, payload) {
      console.log("createSnackbar", payload);
      state.snackbars = [...state.snackbars, payload];
    },
    removeSnackbar(state, payload) {
      console.log("removeSnackbar", payload);
      state.snackbars = state.snackbars.filter((snackbar) => snackbar.uid !== payload);
    },
    setSnackbarProgress(state, payload) {
      console.log("setSnackbarProgress", payload);
      state.snackbars = state.snackbars.map((snackbar) => {
        if (snackbar.uid === payload.uid) {
          return {
            ...snackbar,
            progress: payload.progress * 100,
          };
        }
        return snackbar;
      });
    },
    closeDialog(state) {
      console.log("closeDialog");
      state.dialog = {
        isOpen: false,
      };
    },
    shareLesson(state, payload) {
      console.log("shareLesson", payload);
      state.shareLesson = {
        ...payload,
        isOpen: true,
      };
    },
    closeShareLesson(state) {
      console.log("closeShareLesson");
      state.shareLesson = {
        isOpen: false,
      };
    },
    setDialog(state, payload) {
      console.log("setDialog", payload);
      state.dialog = {
        ...payload,
        isOpen: true,
      };
    },
    setUsedLicense(state, payload) {
      console.log("setUsedLicense", payload);
      const id = payload.id;
      const newLicense = payload.license;
      state.licensesData = {
        ...state.licensesData,
        [id]: newLicense,
      };
      state.userData = {
        ...state.userData,
        licenses: [...state.userData.licenses, id],
      };
    },
    doCreateLesson(state, payload) {
      console.log("doCreateLesson", payload);
      const id = payload.id;
      const newLesson = payload.lesson;
      state.lessonsData = {
        ...state.lessonsData,
        [id]: newLesson,
      };
      state.userData = {
        ...state.userData,
        lessons: [...state.userData.lessons, id],
      };
    },
    doDeleteLesson(state, payload) {
      console.log("doDeleteLesson", payload);
      const id = payload.id;
      const lessonsData = { ...state.lessonsData };
      delete lessonsData[id];
      state.lessonsData = lessonsData;
      state.userData = {
        ...state.userData,
        lessons: state.userData.lessons.filter((lesson) => lesson !== id),
      };
    },
    setLanguage(state, payload) {
      console.log("setLanguage", payload);
      state.userData = {
        ...state.userData,
        language: payload,
      };
    },
    setHistoryLength(state, payload) {
      console.log("setHistoryLength", payload);
      state.startHistoryLength = payload;
    },
    setScore(state, payload) {
      console.log("setScore", payload);
      state.userData = {
        ...state.userData,
        scores: {
          ...state.userData.scores,
          [payload.lessonId]: Math.max(payload.score, state.userData.scores[payload.lessonId] || 0),
        },
      };
    },
  },
  actions: {
    // firebase login
    loginWithGoogle({ commit }) {
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          const provider = new firebase.auth.GoogleAuthProvider();
          firebase.auth().useDeviceLanguage();
          firebase
            .auth()
            .signInWithPopup(provider)
            .then(({ user }) => {
              // commit("setUser", user);
            })
            .catch((error) => {
              console.log("fuck me");
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onAuthStateChanged({ commit, dispatch }) {
      firebase.auth().onAuthStateChanged((user) => {
        commit("setUser", user);
        dispatch("updateUserData");
      });
    },
    async updateUserData({ commit, dispatch, state }) {
      if (!state.user) {
        commit("setUserData", null);
        commit("setLessonsData", null);
        commit("setLicensesData", null);
        commit("setBoughtLicensesData", null);
        commit("setIsUserLoaded", false);
        if (state.unsubscribeToUser) state.unsubscribeToUser();
      } else {
        commit("setIsUserLoaded", false);
        const uid = state.user.uid;
        // get user data from firebase
        const firestore = Vue.prototype.$firestore;
        const usersDb = firestore.collection("users");
        const licensesDb = firestore.collection("licenses");
        const lessonsDb = firestore.collection("lessons");
        const unsubscribe = usersDb.doc(uid).onSnapshot(async (doc) => {
          const userData = doc.data();
          let licensesData = {};
          const lessonsData = {};
          const boughtlicensesData = {};
          const promises = [];
          promises.push(
            (async () => {
              // use getMyLicenses httpCallable function to get user licenses
              const response = (await Vue.prototype.$functions.httpsCallable("getMyLicenses")())
                .data;
              if (response.error) {
                licensesData = {};
              } else {
                licensesData = response;
              }
              console.log("licensesData", licensesData);
            })()
          );
          promises.push(
            ...userData.boughtLicenses.map(async (licenseId) => {
              boughtlicensesData[licenseId] = await (await licensesDb.doc(licenseId).get()).data();
            })
          );
          promises.push(
            ...userData.lessons.map(async (lessonId) => {
              lessonsData[lessonId] = await (await lessonsDb.doc(lessonId).get()).data();
            })
          );
          await Promise.all(promises);
          commit("setUserData", userData);
          commit("setLicensesData", licensesData);
          commit("setLessonsData", lessonsData);
          commit("setBoughtLicensesData", boughtlicensesData);
          commit("setIsUserLoaded", true);
        });
        commit("setUnsubscribeToUser", unsubscribe);
      }
    },
    logout({ commit }) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          commit("setIsUserLoaded", false);
          commit("setUser", null);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async subscribe({ commit }, { price, quantity }) {
      const selectedPrice = {
        price,
        quantity,
      };
      const selectedPrices = [
        {
          price: "price_1LQrcHDGUgtZ68AH6RpJRB5m",
          adjustable_quantity: {
            enabled: true,
            minimum: 1,
            maximum: 5,
          },
          quantity: 1,
        },
        {
          price: "price_1LQrcWDGUgtZ68AHHOw8fSUi",
          adjustable_quantity: {
            enabled: true,
            minimum: 5,
            maximum: 999,
          },
          quantity: 5,
        },
      ];
      const checkoutSession = {
        automatic_tax: true,
        tax_id_collection: true,
        collect_shipping_address: true,
        allow_promotion_codes: true,
        line_items: selectedPrices,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      };

      const currentUser = firebase.auth().currentUser.uid;
      console.log(checkoutSession);

      const docRef = await firebase
        .firestore()
        .collection("customers")
        .doc(currentUser)
        .collection("checkout_sessions")
        .add(checkoutSession);

      console.log(docRef);
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot((snap) => {
        const { error, url } = snap.data();
        if (error) {
          // Show an error to your customer and then inspect your function logs.
          console.error(`An error occured: ${error.message}`);
        }
        if (url) {
          window.location.assign(url);
        }
      });
    },
    async buyLicense({ commit }, { quantity }) {
      const createStripeCheckout = firebase.functions().httpsCallable("createStripeCheckout");
      const cancelUrl = window.location.href;
      const successUrl = window.location.href;
      const response = await createStripeCheckout({ cancelUrl, successUrl, quantity });
      const sessionId = response.data.id;
      maybeWaitForStripe(() => {
        stripe.redirectToCheckout({ sessionId });
      });
    },
    invalidateUser({ commit, state }) {
      commit("setIsUserLoaded", false);
    },
    async useLicense({ commit, state }, { licenseId }) {
      const res = await Vue.prototype.$functions.httpsCallable("useLicense")({ licenseId });
      console.log(res);
      if (res.data.error) {
        this.dispatch("createSnackbar", {
          text: res.data.error,
          type: "error",
        });
      } else if (res.data.success) {
        this.dispatch("createSnackbar", {
          text: window.root.$t("snackbars.licenses.activated"),
          type: "success",
        });
        const license = res.data.license;
        const id = res.data.id;
        // add license ID to user's boughtLicenses array
        commit("setUsedLicense", {
          license: { ...license, active: true, activationDate: { seconds: Date.now() / 1000 } },
          id,
        });
      } else {
        this.dispatch("createSnackbar", {
          text: window.root.$t("snackbars.licenses.internalError"),
          type: "error",
        });
      }
    },
    createSnackbar({ commit }, { text = "", type = "info", timeout = 2000 }) {
      const uid = nanoid();
      const item = {
        text,
        type,
        timeout,
        uid,
        startTime: Date.now(),
      };
      commit("createSnackbar", item);
      const interval = setInterval(() => {
        const duration = item.timeout;
        const now = Date.now();
        const start = item.startTime;
        const end = start + duration;
        let progress = (now - start) / (end - start);
        if (progress >= 1) {
          clearInterval(interval);
          progress = 1;
        }
        commit("setSnackbarProgress", { uid, progress });
      }, 100);
      setTimeout(() => {
        this.dispatch("removeSnackbar", { uid });
      }, timeout + 1000);
    },
    removeSnackbar({ commit, state }, { uid }) {
      // check that the snackbar is not already removed
      if (state.snackbars.find((snackbar) => snackbar.uid === uid)) {
        commit("removeSnackbar", uid);
      }
    },
    async createLesson({ commit, state }) {
      const firestore = Vue.prototype.$firestore;
      const usersDb = firestore.collection("users");
      const lessonsDb = firestore.collection("lessons");
      const currentUser = firebase.auth().currentUser.uid;
      const lesson = {
        name: "New lesson",
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        owner: currentUser,
        ownerName: state.userData.displayName,
        ownerPhoto: state.userData.photoUrl,
        language1: "en",
        language2: "fr",
        words: [],
      };
      const lessonRef = await lessonsDb.add(lesson);
      const lessonId = lessonRef.id;
      await lessonRef.set(
        {
          id: lessonId,
        },
        { merge: true }
      );

      commit("doCreateLesson", { lesson, id: lessonId });
      await usersDb.doc(currentUser).set(
        {
          lessons: firebase.firestore.FieldValue.arrayUnion(lessonId),
        },
        { merge: true }
      );
      // add a snackbar
      this.dispatch("createSnackbar", {
        text: window.root.$t("snackbars.lessons.created"),
        type: "info",
      });
      return lessonId;
    },
    async duplicateLesson({ commit, state }, { id }) {
      const lessonId = id;
      const firestore = Vue.prototype.$firestore;
      const usersDb = firestore.collection("users");
      const lessonsDb = firestore.collection("lessons");
      const lesson = state.lessonsData[lessonId];
      const currentUser = firebase.auth().currentUser.uid;
      const newLesson = {
        ...lesson,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        id: null,
        owner: currentUser,
        ownerName: state.userData.displayName,
        ownerPhoto: state.userData.photoUrl,
      };
      const lessonRef = await lessonsDb.add(newLesson);
      const newLessonId = lessonRef.id;
      await lessonRef.set(
        {
          id: newLessonId,
        },
        { merge: true }
      );

      commit("doCreateLesson", {
        lesson: { ...newLesson, id: newLessonId, createdAt: { seconds: Date.now() / 1000 } },
        id: newLessonId,
      });
      await usersDb.doc(currentUser).set(
        {
          lessons: firebase.firestore.FieldValue.arrayUnion(newLessonId),
        },
        { merge: true }
      );
      // add a snackbar
      this.dispatch("createSnackbar", {
        text: window.root.$t("snackbars.lessons.duplicated"),
        type: "info",
      });
      return newLessonId;
    },
    async saveLesson({ commit, state }, { id, lesson }) {
      const firestore = Vue.prototype.$firestore;
      const lessonsDb = firestore.collection("lessons");
      await lessonsDb.doc(id).set(lesson, { merge: true });
      // add a snackbar
      this.dispatch("createSnackbar", {
        text: window.root.$t("snackbars.lessons.saved"),
        type: "info",
      });
      // update state
      commit("setLesson", { id, lesson });
    },
    async deleteLesson({ commit, state }, { id }) {
      const firestore = Vue.prototype.$firestore;
      const usersDb = firestore.collection("users");
      const lessonsDb = firestore.collection("lessons");
      const lesson = state.lessonsData[id];
      const currentUser = firebase.auth().currentUser.uid;

      commit("doDeleteLesson", { id });
      await usersDb.doc(currentUser).update({
        lessons: firebase.firestore.FieldValue.arrayRemove(id),
      });

      // if owner, call delete function
      if (lesson.owner === currentUser) {
        const result = (
          await Vue.prototype.$functions.httpsCallable("deleteLesson")({
            lessonId: id,
          })
        ).data;
        if (result.error) {
          this.dispatch("createSnackbar", {
            text: window.root.$t("snackbars.lessons.deleteError"),
            type: "error",
          });
        }
        if (result.success) {
          this.dispatch("createSnackbar", {
            text: window.root.$t("snackbars.lessons.deleted"),
            type: "info",
          });
        }
      } else {
        // add a snackbar
        this.dispatch("createSnackbar", {
          text: window.root.$t("snackbars.lessons.deleted"),
          type: "info",
        });
      }
      // update state
      commit("removeLesson", id);
    },
    confirmDialog({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setDialog", { ...payload, resolve, reject });
      });
    },
    async changeLanguage({ commit }, language) {
      // change locale in vue i18n
      const firestore = Vue.prototype.$firestore;
      const usersDb = firestore.collection("users");
      const currentUser = firebase.auth().currentUser.uid;
      console.log(Vue.prototype);
      console.log(Vue.prototype.$vueI18n);
      Vue.prototype.$vueI18n.locale = language.code;
      await usersDb.doc(currentUser).update({
        language,
      });
      commit("setLanguage", language);
      // add a snackbar
      this.dispatch("createSnackbar", {
        text: window.root.$t("snackbars.language.changed"),
        type: "info",
      });
    },
    async getLesson({ commit, state }, id) {
      const response = (await Vue.prototype.$functions.httpsCallable("getLesson")({ lessonId: id }))
        .data;
      const lesson = response.lesson;
      commit("setLesson", { id, lesson });
    },
    initHistoryLength({ commit }) {
      commit("setHistoryLength", window.history.length);
    },
    async saveScore({ commit, state }, { lessonId, score }) {
      const firestore = Vue.prototype.$firestore;
      // call submitScore function
      this.dispatch("createSnackbar", {
        text: window.root.$t("snackbars.score.saving"),
        type: "info",
      });
      commit("setScore", { lessonId, score });
      const response = (
        await Vue.prototype.$functions.httpsCallable("submitScore")({
          lessonId,
          score,
        })
      ).data;
      if (response.success) {
        // add a snackbar
        this.dispatch("createSnackbar", {
          text: window.root.$t("snackbars.score.saved"),
          type: "success",
        });
      }
      if (response.error) {
        // add a snackbar
        this.dispatch("createSnackbar", {
          text: window.root.$t("snackbars.score.error"),
          type: "error",
        });
      }
    },
  },
  modules: {},
});
