<template>
  <CenterView fullScreen>
    <Card
      fullScreenIfMobile
      useStyle="max-width: 80vw; width: 80vw; max-height: 80vh; margin-top: 5vh"
    >
      <CenterView v-if="isUserLoaded && !loading && !isOut" style="padding: 10px; overflow-y: none">
        <div
          style="
            display: flex;
            align-items: flex-start;
            width: 100%;
            justify-content: flex-start;
            flex-wrap: wrap;
          "
        >
          <!-- textbox with lesson name, with a play button and a share button -->
          <v-text-field
            v-model="lesson.name"
            :label="$t('lessons.name')"
            outlined
            filled
            dense
            dark
            hide-details=""
          ></v-text-field>
          <!-- tooltip -->
          <v-tooltip top v-if="!lesson.words || lesson.words.length < 5">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn color="primary" height="40" dark disabled>
                  <v-icon> mdi-play </v-icon>
                  {{ $t("lessons.play") }}
                </v-btn>
              </div>
            </template>
            <span>{{ $t("lessons.cantPlay") }}</span>
          </v-tooltip>
          <v-btn v-else color="primary" height="40" dark @click="play">
            <v-icon> mdi-play </v-icon>
            {{ $t("lessons.play") }}
          </v-btn>
          <v-btn color="primary" height="40" dark @click="share">
            <v-icon> mdi-share </v-icon>
            {{ $t("lessons.share") }}
          </v-btn>
        </div>
        <div
          v-if="lesson.language1 && lesson.language2"
          style="
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: flex-start;
            margin-top: 10px;
            flex-wrap: wrap;
          "
        >
          <!-- Source and translation menus -->
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn depressed rounded text dark v-on="on">
                <span style="padding-right: 5px"> {{ $t("lessons.source") }}:</span>
                <flag :iso="languages.find((l) => l.code === lesson.language1).iso" />
                <span style="padding-left: 5px">
                  {{ languages.find((l) => l.code === lesson.language1).name }}
                </span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="language in languages"
                :key="language.code"
                @click="changeLanguage1(language)"
              >
                <flag :iso="language.iso" />
                <v-list-item-title>{{ language.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-icon dark>mdi-arrow-right</v-icon>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn depressed rounded text dark v-on="on">
                <span style="padding-right: 5px"> {{ $t("lessons.source") }}:</span>
                <flag :iso="languages.find((l) => l.code === lesson.language2).iso" />
                <span style="padding-left: 5px">
                  {{ languages.find((l) => l.code === lesson.language2).name }}
                </span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="language in languages"
                :key="language.code"
                @click="changeLanguage2(language)"
              >
                <flag :iso="language.iso" />
                <v-list-item-title>{{ language.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div
          class="header white--text text-left"
          style="align-self: flex-start; margin-left: 10px; margin-top: 10px"
        >
          <h3>{{ $t("lessons.words") }}</h3>
        </div>

        <v-list
          dark
          style="
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 10px;
            width: 100%;
            min-height: 150px;
          "
        >
          <!-- grid -->
          <v-layout row wrap>
            <div
              style="
                flex-direction: column;
                min-width: 165px;
                max-width: 220px;
                max-height: 150px;
                height: 150px;
                margin: 4px 2px;
                flex: 1;
              "
            >
              <v-btn
                style="width: calc(100% - 20px); height: calc(100% - 20px); margin: 10px"
                filled
                outlined
                color="primary"
                @click="addWord"
              >
                <v-icon>mdi-plus</v-icon></v-btn
              >
            </div>
            <div
              v-for="(wordSet, index) in lesson.words"
              :key="index"
              style="
                max-height: 150px;
                min-width: 165px;
                max-width: 220px;
                height: 150px;
                margin: 4px 2px;
                flex: 1;
              "
            >
              <div
                style="
                  border: 1px solid #e0e0e0;
                  border-radius: 4px;
                  margin: 10px;
                  padding: 5px;
                  width: calc(100% - 20px);
                  height: calc(100% - 20px);
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: center;
                  align-content: center;
                  position: relative;
                "
              >
                <v-text-field
                  v-model="wordSet.source"
                  :label="$t('lessons.source')"
                  outlined
                  filled
                  dense
                  dark
                  hide-details
                  style="height: 40px; max-height: 40px"
                ></v-text-field>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <v-icon>mdi-arrow-down</v-icon>

                  <v-btn
                    style="position: absolute; right: 0"
                    color="error"
                    icon
                    small
                    @click="deleteWord(index)"
                    ><v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <v-text-field
                  v-model="wordSet.translation"
                  :label="$t('lessons.translation')"
                  outlined
                  filled
                  dense
                  dark
                  hide-details
                  style="height: 40px; max-height: 40px"
                ></v-text-field>
              </div>
            </div>
          </v-layout>
        </v-list>
        <!-- chip list of every user in lesson -->
        <div v-if="lesson.users && lesson.users.length > 0" style="width: 100%">
          <div
            class="header white--text text-left"
            style="align-self: flex-start; margin-left: 10px; margin-top: 10px"
          >
            <h3>{{ $t("lessons.users") }}</h3>
          </div>
          <div
            style="
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;
              width: 100%;
              padding: 10px;
            "
          >
            <v-tooltip bottom v-for="user in orderedUsers" :key="user.id">
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" style="margin-bottom: 5px; margin-left: 5px">
                  <v-avatar
                    left
                    size="32"
                    color="grey lighten-4"
                    class="white--text"
                    style="margin-right: 5px"
                  >
                    <img :src="user.photoUrl" alt="profile picture" />
                  </v-avatar>
                  {{ user.name }}
                </v-chip>
              </template>
              <span>{{ `${$t("lessons.score")}: ${scoreToPercents(user.score)}%` }}</span>
            </v-tooltip>
          </div>
        </div>
      </CenterView>
      <CenterView v-else>
        <v-progress-circular
          style="margin: 10px"
          size="50"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </CenterView>
    </Card>
  </CenterView>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "Lesson",
  data() {
    return {
      loading: false,
      isOut: false,
      id: "",
      lesson: {},
      orderedUsers: [],
    };
  },
  computed: {
    ...mapState({
      user: "user",
      userData: "userData",
      lessonsData: "lessonsData",
      isUserLoaded: "isUserLoaded",
      languages: "languages",
    }),
  },
  watch: {
    lessonsData() {
      this.lesson = JSON.parse(JSON.stringify(this.lessonsData[this.id]));
      this.loading = false;
      if (this.lesson.users) {
        this.orderedUsers = this.lesson.users.sort((a, b) => b.score - a.score);
      }
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    if (this.lessonsData && this.lessonsData[this.id]) {
      this.lesson = JSON.parse(JSON.stringify(this.lessonsData[this.id]));
      if (this.lesson.users) {
        this.orderedUsers = this.lesson.users.sort((a, b) => b.score - a.score);
      }
    } else {
      this.loading = true;
    }
  },

  async beforeRouteLeave(to, from, next) {
    if (to.path === "/") {
      this.isOut = true;
      next();
    } else {
      this.isOut = true;
      await this.doSaveLesson();
      next();
    }
  },
  components: {},
  methods: {
    ...mapActions(["getUser", "saveLesson"]),
    ...mapMutations({
      shareLesson: "shareLesson",
    }),
    play() {
      this.$router.push({
        name: "Play",
        params: {
          id: this.id,
        },
      });
    },
    share() {
      this.shareLesson(this.lesson);
    },
    addWord() {
      this.lesson.words.push({
        source: "",
        translation: "",
      });
    },
    deleteWord(index) {
      this.lesson.words.splice(index, 1);
    },
    async doSaveLesson() {
      this.loading = true;
      await this.saveLesson({
        id: this.id,
        lesson: this.lesson,
      });
      this.loading = false;
    },
    changeLanguage1(language) {
      this.lesson.language1 = language.code;
    },
    changeLanguage2(language) {
      this.lesson.language2 = language.code;
    },
    scoreToPercents(score) {
      if (this.lesson.words.length === 0) {
        return 0;
      }
      if (typeof score !== "number") {
        return 0;
      }
      const percent = Math.round((score / this.lesson.words.length) * 100);
      return percent > 100 ? 100 : percent;
    },
  },
};
</script>
