<template>
  <v-dialog
    dark
    v-model="show"
    :persistent="dialog.persistent"
    :max-width="mobileMode ? '100%' : '60vw'"
    width="fit-content"
    elevation="20"
    overlay-opacity="0.5"
    overlay-color="black"
    @click:outside="maybeOnCancel"
  >
    <v-card>
      <v-card-title>{{ dialog.title }}</v-card-title>
      <v-card-text>{{ dialog.message }}</v-card-text>
      <v-card-actions
        :style="
          mobileMode
            ? 'justify-content: center !important; flex-wrap: wrap;'
            : 'justify-content: flex-end'
        "
      >
        <v-btn
          :color="dialog.type || 'primary'"
          style="margin-top: 5px; margin-left: 8px"
          @click="doOnOk"
          >{{ dialog.okText }}</v-btn
        >
        <v-btn color="secondary" style="margin-top: 5px" @click="onCancel">{{
          dialog.cancelText
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "Dialog",
  props: {},
  data() {
    return {
      show: true,
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      dialog: "dialog",
    }),
    mobileMode() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapMutations({
      closeDialog: "closeDialog",
    }),
    doOnOk() {
      if (this.dialog.onOk) this.dialog.onOk();
      this.show = false;
      setTimeout(() => {
        this.dialog.resolve();
        this.closeDialog();
      }, 300);
    },
    onCancel() {
      this.show = false;
      setTimeout(() => {
        this.dialog.resolve();
        this.closeDialog();
      }, 300);
    },
    maybeOnCancel() {
      if (this.dialog.persistent) return;
      this.onCancel();
    },
  },
};
</script>
