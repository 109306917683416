<template>
  <v-layout
    column
    :style="
      fullScreen
        ? `
      width: 100vw;
      min-height: 100vh;
      position: absolute;
      justify-content: center;
      align-content: center;
      align-items: center;
      overflow-y: auto;
      `
        : `
      width: 100%;
      min-height: 100%;
      justify-content: center;
      align-content: center;
      align-items: center;
      overflow-y: auto;
      `
    "
  >
    <slot></slot>
  </v-layout>
</template>

<script>
export default {
  name: "CenterView",
  props: {
    fullScreen: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    children() {
      return this.$slots.default;
    },
  },
  mounted() {},
  methods: {},
};
</script>
