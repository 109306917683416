<template>
  <div v-if="!canLoadGame && !loading">
    <CenterView fullScreen>
      <v-progress-circular size="100" indeterminate color="primary"></v-progress-circular>
      <h3 style="color: white; margin-top: 1em">{{ $t("play.loading") }}</h3>
    </CenterView>
  </div>
  <iframe
    v-else
    :src="gameUrl"
    style="width: 100%; height: calc(100% - 64px); border: none; margin-top: 64px; z-index: 1"
    scrolling="no"
    frameBorder="0"
  ></iframe>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Play",
  data() {
    return {
      loading: false,
      baseGameUrl: "https://static.clu-mo.com/game/",
      id: "",
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    window.onmessage = (e) => {
      // check message comes from static.clu-mo.com
      if (e.origin !== "https://static.clu-mo.com") {
        return;
      }
      const data = e.data;
      if (data.type === "gameEnded") {
        // save score and go back to previous page
        this.saveScore({ lessonId: this.id, score: data.score });
        // if router can go back, go back, else go to home
        if (window.history.length >= this.startHistoryLength) {
          this.$router.go(-1);
        } else {
          this.$router.push({ name: "Home" });
        }
      }
    };
  },
  computed: {
    ...mapState({
      user: "user",
      startHistoryLength: "startHistoryLength",
      userData: "userData",
      lessonsData: "lessonsData",
      isUserLoaded: "isUserLoaded",
    }),
    lesson() {
      return this.lessonsData[this.id];
    },
    canLoadGame() {
      return this.isUserLoaded && this.lesson;
    },
    gameUrl() {
      const data = this.formatLessonData(this.lesson);
      console.log(data);
      return `${this.baseGameUrl}?data=${encodeURIComponent(data)}`;
    },
  },
  methods: {
    ...mapActions(["saveScore"]),
    formatLessonData(lesson) {
      const words = lesson.words || [];
      const WIDTH = 6;
      const data = [];
      for (let i = 0; i < WIDTH; i += 1) {
        data.push([]);
      }
      const topRow = ["EN", "TYPE", "THEMES", "FR", "AF", ""];
      data.forEach((row, rowIndex) => {
        row.push([topRow[rowIndex]]);
      });
      words.forEach((wordSet) => {
        data.forEach((row, rowIndex) => {
          if (rowIndex === 0) {
            row.push([wordSet.translation]);
          } else if (rowIndex === 3) {
            row.push([wordSet.source]);
          } else {
            row.push([""]);
          }
        });
      });
      return JSON.stringify({
        c2array: true,
        size: [WIDTH, words.length + 1, 1],
        data,
      });
    },
  },
  components: {},
};
</script>
