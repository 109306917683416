<template>
  <div
    column
    :style="`
        background-color: rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        border: 10px solid rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        padding: 10px;
        margin: 10px;
        max-width: 500px;
        z-index: 1;
        overflow-y: auto;
        ${
          fullScreenIfMobile && isMobile
            ? 'max-width: 100vw !important; width: 100vw;' +
              'max-height: calc(100vh - 56px); height: calc(100vh - 56px);' +
              'margin-top: 56px !important; border: 0px !important;' +
              'margin-left: 0px !important; margin-right: 0px !important;'
            : useStyle
        }}`"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    useStyle: {
      type: String,
      default: "",
      required: false,
    },
    fullScreenIfMobile: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    children() {
      return this.$slots.default;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {},
  methods: {},
};
</script>
